import React, { useRef, useState } from "react";
import companyLogo from "./assets/companyLogo.png";
import productLogo from "./assets/productLogo.png";

function Background({ children }) {
  const [menuShow, setMenuShow] = useState(false);
  const menu = useRef();
  const overlay = useRef();
  const showMenu = () => {
    setMenuShow(!menuShow);
    menu?.current.classList.toggle("show");
    overlay?.current.classList.toggle("show");
  };
  return (
    <div className="background">
      <div ref={overlay} onClick={showMenu} className="overlay" />
      <nav>
        <div className="logo">
          <div className="companyLogo">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://excellentbridge.com/"
            >
              <img src={companyLogo} alt="Company Logo" />
            </a>
          </div>
          <div className="productLogo">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://invexerp.excellentbridge.com/"
            >
              <img src={productLogo} alt="Product Logo" />
            </a>
          </div>
        </div>
        <div className="links">
          <div ref={menu} className="menu">
            <ul>
              <li>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://invexerp.excellentbridge.com/"
                >
                  Products
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.excellentbridge.com/enterprise-resource-planning-software"
                >
                  Solutions
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.excellentbridge.com/software-development"
                >
                  Services
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://training.excellentbridge.com/"
                >
                  Learning
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.excellentbridge.com/company-information"
                >
                  Abount
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.excellentbridge.com/contact"
                >
                  Contact
                </a>
              </li>
            </ul>
            <button
              onClick={() =>
                (window.location.href =
                  "https://www.excellentbridge.com/request-a-demo")
              }
              type="button"
            >
              Request Demo
            </button>
          </div>
          <div className="hamburger" onClick={showMenu}>
            {menuShow ? (
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13 1L1 13M1 1L13 13"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            ) : (
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3 12H21M3 6H21M3 18H21"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            )}
          </div>
        </div>
      </nav>
      <div className="container">{children}</div>
    </div>
  );
}

export default Background;
