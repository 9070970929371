import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

function Industry({ saveIndustry, next, email }) {
  const backendUrl = process.env.REACT_APP_EB_BACKEND_URL;
  const [selected, setSelected] = useState(null);
  const industry = [
    { value: "manufacturing", label: "Manufacturing" },
    { value: "agriculture", label: "Agriculture" },
    { value: "oil-gas", label: "Oil and Gas" },
    { value: "finance", label: "Finance" },
    { value: "retail", label: "Retail" },
    { value: "services", label: "Services" },
    { value: "wholesales", label: "Wholesale Distribution" },
    { value: "transportation", label: "Transportation" },
    { value: "logistics", label: "Logistics" },
    { value: "healthcare", label: "Healthcare" },
    { value: "technology", label: "Technology" },
    { value: "construction", label: "Construction" },
    { value: "education", label: "Education" },
    { value: "government", label: "Government Agency" },
    { value: "engineering", label: "Engineering" },
    { value: "media", label: "Media" },
    { value: "gas-station", label: "Gas Station" },
    { value: "other", label: "Other" },
  ];

  const submit = async () => {
    try {
      if (!selected) {
        throw new Error(`Please select an industry`);
      }
      let response = await fetch(`${backendUrl}/api/users/add-company-info`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify({ ownerEmail: email, industry: selected }),
      });
      if (!response.ok) {
        response = await response.json();
        throw new Error(response.message);
      }
      next();
    } catch (error) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    saveIndustry(submit);
  }, [selected]);

  const handleSelect = (e) => {
    setSelected(e.target.value);
  };
  return (
    <div className="contents">
      <div className="wrapper">
        <h1>What's your industry?</h1>
        <h5 className="desc">Select the option that best fit.</h5>

        <select list="industry-options" id="industry" onChange={handleSelect}>
          <option value="">Select industry</option>
          {industry.map((item) => (
            <option key={item.value} value={item.value}>
              {item.label}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
}

export default Industry;
