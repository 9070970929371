import React, { useEffect, useState } from "react";
import sole from "./assets/sole.png";
import company from "./assets/company.png";
import others from "./assets/others.png";
import trade from "./assets/trade.png";
import trust from "./assets/trust.png";
import partner from "./assets/partner.png";
import charity from "./assets/charity.png";
import { toast } from "react-toastify";

function BusinessType({ saveBusinessType, next, email }) {
  const [selected, setSelected] = useState("");
  const backendUrl = process.env.REACT_APP_EB_BACKEND_URL;

  const businessType = [
    {
      icon: sole,
      title: "Sole trader",
      value: "sole-trader",
    },
    {
      icon: company,
      title: "Company",
      value: "company",
    },
    {
      icon: trade,
      title: "Trade or coperative",
      value: "trade",
    },
    {
      icon: trust,
      title: "Trust",
      value: "trust",
    },
    {
      icon: partner,
      title: "Partnership",
      value: "partnership",
    },
    {
      icon: charity,
      title: "Charity or Association",
      value: "charity",
    },
    {
      icon: others,
      title: "Others",
      value: "others",
    },
  ];
  const handleSelected = (value) => {
    setSelected(value);
  };

  const submit = async () => {
    try {
      if (!selected) {
        throw new Error(`Please select a business type`);
      }
      let response = await fetch(`${backendUrl}/api/users/add-company-info`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify({ ownerEmail: email, businessType: selected }),
      });
      if (!response.ok) {
        response = await response.json();
        throw new Error(response.message);
      }
      next();
    } catch (error) {
      toast.error(error.message);
    }
  };
  useEffect(() => {
    saveBusinessType(submit);
  }, [selected]);
  return (
    <div className="contents">
      <h1>What kind of business is this?</h1>
      <h5 className="desc">
        Tell us about your business structure, we'll use this to help categorise
        your transaction
      </h5>
      <div className="options">
        {businessType.map((item) => (
          <div className="card" onClick={() => handleSelected(item.value)}>
            <div className="left">
              <h5 className="title">{item.title}</h5>
            </div>
            <span className="selection">
              {selected === item.value ? (
                <svg
                  className="selected"
                  height="512px"
                  id="Layer_1"
                  style={{ enableBackground: "new 0 0 512 512" }}
                  version="1.1"
                  viewBox="0 0 512 512"
                  width="512px"
                  space="preserve"
                >
                  <g>
                    <g>
                      <path d="M256,48C141.1,48,48,141.1,48,256s93.1,208,208,208c114.9,0,208-93.1,208-208S370.9,48,256,48z M256,446.7    c-105.1,0-190.7-85.5-190.7-190.7c0-105.1,85.5-190.7,190.7-190.7c105.1,0,190.7,85.5,190.7,190.7    C446.7,361.1,361.1,446.7,256,446.7z" />
                    </g>
                  </g>
                  <g>
                    <g>
                      <path d="M256,96c-88.4,0-160,71.6-160,160c0,88.4,71.6,160,160,160c88.4,0,160-71.6,160-160C416,167.6,344.4,96,256,96z" />
                    </g>
                  </g>
                </svg>
              ) : (
                <svg
                  height="512px"
                  id="Layer_1"
                  style={{ enableBackground: "new 0 0 512 512" }}
                  version="1.1"
                  viewBox="0 0 512 512"
                  width="512px"
                  space="preserve"
                >
                  <g>
                    <g>
                      <path d="M256,48C141.1,48,48,141.1,48,256s93.1,208,208,208c114.9,0,208-93.1,208-208S370.9,48,256,48z M256,446.7    c-105.1,0-190.7-85.5-190.7-190.7c0-105.1,85.5-190.7,190.7-190.7c105.1,0,190.7,85.5,190.7,190.7    C446.7,361.1,361.1,446.7,256,446.7z" />
                    </g>
                  </g>
                </svg>
              )}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
}

export default BusinessType;
