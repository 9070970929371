import React, { useEffect, useState } from "react";
import paper from "./assets/paper.png";
import cloud from "./assets/cloud.png";
import cloudBook from "./assets/cloudBook.png";
import empty from "./assets/empty.png";

function Reciept({ saveReciept, next }) {
  const [selected, setSelected] = useState("");

  const ReceiptType = [
    {
      icon: paper,
      title: "I save paper receipts",
      value: "paper",
    },
    {
      icon: cloud,
      title: "I save cloud receipts",
      value: "cloud",
    },
    {
      icon: cloudBook,
      title: "I save in paper and cloud",
      value: "cloudBook",
    },
    {
      icon: empty,
      title: "I don't save receipts",
      value: "none",
    },
  ];
  const handleSelected = (value) => {
    console.log(value);
    setSelected(value);
  };

  const submit = async () => {
    console.log("submitting receipt type...", selected);
    next();
  };

  useEffect(() => {
    saveReciept(submit);
  }, [selected]);
  return (
    <div className="contents">
      <h1>How do you keep track of your receipts today?</h1>
      <h5 className="desc">
        Saving receipt is an important part of running your business, you'll
        need them for tax time and for good record keeping.
      </h5>
      <div className="options">
        {ReceiptType.map((item, index) => (
          <div className="card" onClick={() => handleSelected(item.value)}>
            <div className="left">
              <span className="icon">
                <img src={item.icon} alt={item.title} />
              </span>
              <span className="title">{item.title}</span>
            </div>
            <span className="selection">
              {selected === item.value && (
                <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                  <path d="M480 128c0 8.188-3.125 16.38-9.375 22.62l-256 256C208.4 412.9 200.2 416 192 416s-16.38-3.125-22.62-9.375l-128-128C35.13 272.4 32 264.2 32 256c0-18.28 14.95-32 32-32c8.188 0 16.38 3.125 22.62 9.375L192 338.8l233.4-233.4C431.6 99.13 439.8 96 448 96C465.1 96 480 109.7 480 128z" />
                </svg>
              )}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Reciept;
